const STAGING = "preview"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "egudzjxjlpgfyym2gnbxl4ql3chxz5217csk6qpp0p8lg7qu",
  dev: "egudzjxjlpgfyym2gnbxl4ql3chxz5217csk6qpp0p8lg7qu",
  prod: "j144dfex44adjs8i4t7i6ovy7cmu1el2n7ge9ljy5x4ufiuw",
  preview: "cayp1iqmsvezu5ykdffba43yzd6lurs7yyrz2w8jp99ppqav",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}